<template>
  <div id="about" v-html="aboutData.content"></div>
</template>

<script>
export default {
  data() {
    return {
      aboutData: {
        content: {},
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let that = this;
      this.$axios
        .get("/blogApi/getAbout", {
          params: {
            id: this.$route.meta.id,
          },
        })
        .then(function (resp) {
          that.aboutData = resp.data.data;
        });
    },
  },
};
</script>

<style scoped>
#about {
  padding: 20px;
  background: white;
}
</style>